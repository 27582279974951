import * as React from "react"
import { SVGProps } from "react"
const WpMainTitle = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={728}
        height={186}
        fill="none"
        {...props}
    >
        <path
            fill="#fff"
            d="M18.216 145-.534-.455H43.5l5.114 89.49h1.136L88.102-.456h34.659l8.807 89.773h1.137L167.364-.455h44.034L144.352 145h-37.784l-11.08-81.25h-1.136L56 145H18.216Zm232.102-61.364L240.091 145h-39.205L225.034-.455h37.784l-9.375 57.103h1.137c3.551-6.96 8.522-12.382 14.914-16.265 6.392-3.93 13.637-5.894 21.733-5.894 7.671 0 14.063 1.752 19.176 5.255 5.114 3.504 8.736 8.334 10.867 14.489 2.131 6.155 2.533 13.21 1.207 21.165L310.83 145h-39.205l10.227-61.364c.853-5.397.166-9.635-2.059-12.713-2.178-3.077-5.753-4.616-10.725-4.616-3.22 0-6.155.71-8.807 2.13-2.604 1.374-4.782 3.362-6.534 5.966-1.704 2.557-2.841 5.635-3.409 9.233ZM343.18 185.91c-4.545 0-8.783-.355-12.713-1.065-3.93-.663-7.362-1.586-10.298-2.77l13.21-27.841c3.031 1.136 5.824 1.87 8.381 2.202 2.604.331 4.901.142 6.889-.569 1.989-.71 3.622-2.059 4.901-4.048l1.278-1.988-19.46-113.92h39.489l4.971 75.567h1.136l30.54-75.568h42.614l-59.375 118.182c-3.03 6.013-6.818 11.411-11.364 16.193-4.498 4.83-10.038 8.641-16.619 11.435-6.581 2.793-14.441 4.19-23.58 4.19ZM446.518 145 470.666-.455h62.784c10.796 0 19.887 2.131 27.273 6.393 7.386 4.26 12.689 10.25 15.909 17.968 3.22 7.718 3.977 16.738 2.273 27.06-1.705 10.511-5.493 19.578-11.364 27.202-5.824 7.575-13.257 13.423-22.301 17.542-8.996 4.072-19.081 6.108-30.256 6.108h-37.5l5.114-30.682h29.545c4.735 0 8.902-.805 12.5-2.414 3.599-1.658 6.511-3.978 8.736-6.96 2.226-3.03 3.67-6.63 4.333-10.796.663-4.167.355-7.718-.924-10.654-1.231-2.982-3.361-5.255-6.392-6.818-2.983-1.61-6.794-2.415-11.434-2.415h-13.921L486.007 145h-39.489ZM727.768-.455 703.621 145h-32.955l-39.773-76.42h-.852L617.257 145h-39.489L601.916-.455h33.523l39.204 76.137h1.137l12.5-76.137h39.488Z"
        />
    </svg> 

)
export default WpMainTitle
