import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import "./styles.css"
import WPLogo from '../../svgs/WPLogo';

import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { isBrowser, isMobile, isTablet } from 'react-device-detect';



export default function Navbar() {

    const [orientation, setOrientation] = React.useState('');

    React.useEffect(() => {

        function handleOrientationChange(event: any) {
            
            const { matches, media } = event;

            if (media == '(orientation: portrait)') {
                setOrientation('portrait');
            } else if (mediaQueryLandscape) {
                setOrientation('landscape');
            }
        }
        
        const mediaQueryPortrait = window.matchMedia('(orientation: portrait)');
        const mediaQueryLandscape = window.matchMedia('(orientation: landscape)');

        if (mediaQueryPortrait.matches) {
            setOrientation('portrait');
        } else if (mediaQueryLandscape.matches) {
            setOrientation('landscape');
        }

        mediaQueryPortrait.addListener(handleOrientationChange);
        mediaQueryLandscape.addListener(handleOrientationChange);

        return () => {
            mediaQueryPortrait.removeListener(handleOrientationChange);
            mediaQueryLandscape.removeListener(handleOrientationChange);
        };

    }, []);

    var onTelegramClick = () => {
        window.open("https://t.me/whypnbot", "_blank")
    }
    
    var onYoutubeClick = () => {
        window.open("https://www.youtube.com/@whypn9781", "_blank")
    }


    var onLkClick = () => {
        window.open("https://lk.whypn.com", "_blank")
    }

    var onCameraClick = () => {
        window.open("https://shorturl.at/mptEU", "_blank")
    }

    var onLogoClick = () => {
        window.open("https://whypn.com", "_blank")
    }




    return (
        <Box sx={{ flexGrow: 1, pt: 3 }}>

            {isBrowser || (isMobile && orientation == 'landscape') ?

                <AppBar position="static" /* className={"navbar_"} */ style={{background: "transparent", boxShadow:'none'}}>
                    <Toolbar>

                        <WPLogo onClick={onLogoClick}></WPLogo>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                            <Box className='navbar__item' sx={{ mr: 5 }} onClick={onTelegramClick}>
                                <TelegramIcon fontSize='large' className='navbar__icon' />
                                <Typography>Telegram</Typography>
                            </Box>

                            <Box className='navbar__item' sx={{ mr: 5 }}  onClick={onYoutubeClick}>
                                <YouTubeIcon fontSize='large' className='navbar__icon' />
                                <Typography>Youtube</Typography>
                            </Box>

                            <Box className='navbar__item navbar__item_border' sx={{ mr: 5, pr: 5 }}  onClick={onCameraClick}>
                                <CameraAltIcon fontSize='large' className='navbar__icon' />
                                <Typography>Instant</Typography>
                            </Box>

                            <Box className='navbar__item' sx={{ mr: 5 }} onClick={onLkClick}>
                                <AccountCircle fontSize='large' className='navbar__icon' />
                                <Typography>ЛК</Typography>
                            </Box>



                        </Box>
                    </Toolbar>
                </AppBar>

                :
                <AppBar position="fixed" className={"navbar_"} sx={{ top: 16 }} style={{background: "transparent", boxShadow:'none'}}>
                    <Toolbar style={{ justifyContent: "space-between" }}>

                        <WPLogo onClick={onLogoClick}></WPLogo>

                        <Box className='navbar__item'  onClick={onLkClick}>
                            <AccountCircleIcon fontSize='large' className='navbar__icon' />
                            <Typography>ЛК</Typography>
                        </Box>

                    </Toolbar>
                </AppBar>
            }
            {
                isBrowser || (isMobile && orientation == 'landscape') ?
                    <></>
                    : <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 16 }} className='navbar__mobile'>
                        <Toolbar style={{ justifyContent: "space-between" }}>
                            <Box className='navbar__item' sx={{ mr: 5 }}  onClick={onTelegramClick}>
                                <TelegramIcon fontSize='large' className='navbar__icon' />
                                <Typography>Telegram</Typography>
                            </Box>

                            <Box className='navbar__item' sx={{ mr: 5 }} onClick={onYoutubeClick}>
                                <YouTubeIcon fontSize='large' className='navbar__icon' />
                                <Typography>Youtube</Typography>
                            </Box>

                            <Box className='navbar__item' onClick={onCameraClick}>
                                <CameraAltIcon fontSize='large' className='navbar__icon' />
                                <Typography>Instant</Typography>
                            </Box>


                        </Toolbar>
                    </AppBar>

            }
        </Box>
    );
}