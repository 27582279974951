import * as React from "react"
import { SVGProps } from "react"
const WPLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={69}
    height={78}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M19.913 2.73C25.203 1.292 30.823 0 34.188 0c3.364 0 8.985 1.292 14.275 2.73 5.41 1.462 10.866 3.193 14.074 4.241a7.507 7.507 0 0 1 5.09 6.152c2.905 21.826-3.838 38.001-12.018 48.702a57.405 57.405 0 0 1-12.27 11.958 34.901 34.901 0 0 1-5.11 3.047c-1.364.644-2.831 1.17-4.04 1.17-1.21 0-2.672-.526-4.042-1.17a34.901 34.901 0 0 1-5.109-3.047 57.411 57.411 0 0 1-12.27-11.958C4.587 51.125-2.156 34.949.75 13.123a7.507 7.507 0 0 1 5.09-6.152A304.486 304.486 0 0 1 19.912 2.73Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M22.154 27.257c-1.101 0-1.993-.919-1.822-2.005 1.066-6.597 6.092-11.406 14.815-11.406 8.726 0 14.609 5.275 14.609 12.469 0 5.212-2.58 8.873-6.942 11.53-4.266 2.556-5.484 4.336-5.484 7.795v.117a1.94 1.94 0 0 1-1.94 1.939h-2.985a1.939 1.939 0 0 1-1.94-1.92L30.454 45c-.167-4.735 1.85-7.76 6.38-10.518 3.994-2.45 5.417-4.401 5.417-7.865 0-3.797-2.94-6.585-7.47-6.585-3.912 0-6.63 2.052-7.515 5.437-.256.985-1.078 1.788-2.094 1.788h-3.018ZM33.87 60.385c2.413 0 4.247-1.838 4.247-4.227 0-2.397-1.834-4.235-4.246-4.235-2.35 0-4.216 1.838-4.216 4.231s1.865 4.231 4.215 4.231Z"
      clipRule="evenodd"
    />
  </svg>
)
export default WPLogo
