import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
  useRoutes,
  BrowserRouter as Router,
} from 'react-router-dom';
import Main from './components/Main/Main';
import Navbar from './components/Navbar/Navbar';
import { Box, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';

function AppRoutes(props: any) {


  const routes = useRoutes(
    [
      { path: '/', element: <Main /> },
    ]
  )
  return routes;
}


const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',

    ].join(','),
  },
})

function App() {
  return (<ThemeProvider theme={theme}>
   {/*  <div className="ocean">
    */}

<div className="ocean">
  <div className="wave"></div>
  <div className="wave"></div>
  <div className="wave"></div>
{/*   <div className="wave"></div>
  <div className="wave"></div> */}
</div>
   
    <Container maxWidth="lg" style={{ height: '100%' }}>
      <div className="App">

        <Router>
          <Navbar></Navbar>
          {/* <Box style={{height:'100%'}} > */}
          <AppRoutes></AppRoutes>
          {/*     </Box> */}

        </Router>
      </div>
    </Container>
  </ThemeProvider>
  );
}

export default App;
